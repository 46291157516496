/* purgecss start ignore */
@tailwind base;

@tailwind components;
/* purgecss end ignore */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&family=Overpass:wght@100;300;400;500;600;700&family=Oxygen:wght@300;400;700&display=swap');

@tailwind utilities;

@layer components{
    *{
        cursor: none;
    }
    .inset-center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .inset-left-center {
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-25%, -50%);
    }
    .inset-center {
        position: absolute;
        top: 50%;
        right: 25%;
        transform: translate(-50%, -50%);
    }
    .custom-cursor{
        pointer-events:none;
    }


}